.no-padding {
    padding: 0;
}

.no-margin {
    margin: 0;
}

.flex-no-grow {
    flex-grow: 0;
    flex-shrink: 0;
}
